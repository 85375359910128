document.addEventListener('DOMContentLoaded', function () {
  const newsLetterModal = document.getElementById('doneModalNewsletter');
  const emailInput = document.getElementById('emailNewsletter');
  const submitButton = document.getElementById('submitNewsletter');
  let hubspotEmailInput;

  emailInput.addEventListener('input', function () {
    if (emailInput.validity.valid) {
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.setAttribute('disabled', 'true');
    }
  });

  window.newsletter = {
    update: function () {
      if (emailInput.validity.valid) {
        submitButton.removeAttribute('disabled');
      } else {
        submitButton.setAttribute('disabled', 'true');
      }
    },
    show: function () {
      if (hubspotEmailInput) {
        hubspotEmailInput.value = emailInput.value;
      }
      newsLetterModal.showModal();
    },
    close: function () {
      newsLetterModal.close();
    },

    setHubspotEmailInput: function (input) {
      hubspotEmailInput = input;
    }
  };
});
